import { Form, Saving, Heading } from '../../components';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { beablooApi } from '../../utils';
import { useEffect, useState } from 'react';
import { addError } from '../../utils';
import { addNotification } from '../../utils';
import { SignTouchPreview } from '../../components';
import { PreviewDynamicPage, PreviewMortgageCalculator } from '../../components/SignTouchPreview/pages';
import './index.scss';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { setMortgageCalculator } from '../../store/actions/mortgage-calculator';
import { updateDynamicPages } from '../../store/actions/dynamicPages';

const EditDynamicPage = () => {
  const { developmentId, pageId } = useParams();
  const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);

  const { data } = useSelector((state) => state.dynamicPages);
  const { dynamicModules } = useSelector((state) => state.properties);
  const module = dynamicModules[`module_${pageId}`];
  const { content, codeText, codeUrl, codeHeading, coverMedia, mediaType, id, menuAlignment = false } = data.find((page) => page.pageId === pageId);

  const [editedContent, setContent] = useState(content);
  const [editedCodeText, setCodeText] = useState(codeText);
  const [editedCodeUrl, setCodeUrl] = useState(codeUrl);
  const [editedMedia, setMedia] = useState(coverMedia);
  const [editiedMediaType, setMediaType] = useState(mediaType);
  const [editedCodeHeading, setCodeHeading] = useState(codeHeading);
  const [editedAlignment, setAlignment] = useState(menuAlignment);

  // const socket = useSocket();
  const dispatch = useDispatch();

  const handleSubmit = async (data) => {
    // trigger spinner
    setSaving(true);
    let pendingId = addPending('Saving...');

    const payload = {
      content: editedContent,
      codeUrl: editedCodeUrl,
      codeText: editedCodeText,
      coverMedia: editedMedia,
      mediaType: editiedMediaType,
      codeHeading: editedCodeHeading,
      menuAlignment: editedAlignment,
    };

    await beablooApi({
      method: 'PUT',
      route: `/developments/${developmentId}/dynamicPages/update/${id}`,
      payload: payload,
    }).then((r) => {
      removePending(pendingId);
      if (!r.success) {
        setSaving(false);
        addError(r.message);
        setErrors(r.errors);
      } else {
        setSaving(false); // stop loading animation
        dispatch(updateDynamicPages(r.data)); // update the redux state
        addNotification(`${module.title} page has been updated.`);
      }
    });
  };

  const handleChange = (data) => {
    if (data.content) {
      setContent(data.content);
    }

    if (data.codeText) {
      setCodeText(data.codeText);
    }

    if (data.coverMedia) {
      setMedia(data.coverMedia);
      if (typeof data.coverMedia == 'string') {
        if (data.coverMedia.includes('image')) {
          setMediaType('image');
        } else if (data.coverMedia.includes('video')) {
          setMediaType('video');
        }
      }
    }

    setCodeUrl(data.codeUrl);
    setCodeHeading(data.codeHeading);

    if (data.menuAlignment !== undefined) {
      setAlignment(data.menuAlignment);
    }
  };

  // useEffect(() => {}, [editedAlignment]);

  return (
    <div className='create-option page-padding'>
      <div className={'form-preview-container'}>
        <Heading label={`Edit ${module.title} Page`} />
        <Form
          errors={errors}
          onChange={handleChange}
          onSubmit={handleSubmit}
          canSubmit={!saving}
          inputs={[
            {
              name: 'content',
              type: 'textarea',
              label: 'Main Content',
              initialValue: editedContent,
              bgType: 'section',
            },
            {
              name: 'codeText',
              type: 'textarea',
              label: 'Code Content',
              initialValue: editedCodeText,
              bgType: 'section',
            },
            {
              name: 'coverMedia',
              label: 'Use ',
              type: 'single-image',
              switch: true,
              mediaType: editiedMediaType,
              initialValue: editedMedia,
            },
            {
              name: 'menuAlignment',
              type: 'switch',
              label: `${editedAlignment ? 'Right' : 'Left'} Aligned`,
              initialValue: editedAlignment,
            },
            {
              name: 'codeHeading',
              type: 'text',
              label: 'QR Code Location',
              initialValue: editedCodeHeading,
            },
            {
              name: 'codeUrl',
              type: 'text',
              initialValue: editedCodeUrl,
            },
          ]}
        />
      </div>
      <div style={{ position: 'fixed', right: '10vw', top: '10%', zIndex: 10 }}>
        <SignTouchPreview>
          <PreviewDynamicPage
            content={editedContent ? editedContent : content}
            codeUrl={editedCodeUrl ? editedCodeUrl : codeUrl}
            codeText={editedCodeText ? editedCodeText : codeText}
            coverMedia={editedMedia ? editedMedia : coverMedia}
            mediaType={editiedMediaType ? editiedMediaType : mediaType}
            codeHeading={editedCodeHeading ? editedCodeHeading : codeHeading}
            pageId={pageId}
          />
        </SignTouchPreview>
      </div>
    </div>
  );
};

export default EditDynamicPage;
