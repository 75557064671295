import { SideBar, SideBarItem } from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as MapIcon } from '../../assets/icons/map.svg';
import { ReactComponent as HomeIcon } from '../../assets/img/homeIcon.svg';
import { ReactComponent as PlotIcon } from '../../assets/img/plotIcon.svg';
import { ReactComponent as BrandingIcon } from '../../assets/img/brush.svg';
import { ReactComponent as CategoryIcon } from '../../assets/img/category.svg';
import { ReactComponent as PageIcon } from '../../assets/icons/document.svg';
import { ReactComponent as MetaDataIcon } from '../../assets/img/code-outline.svg';
import { ReactComponent as PuzzleIcon } from '../../assets/img/puzzle-piece.svg';
import { ReactComponent as OptionIcon } from '../../assets/img/icon-options-centre.svg';
import { ReactComponent as TransportLinksIcon } from '../../assets/icons/airplane.svg';
import { ReactComponent as LocalInformationIcon } from '../../assets/icons/information-circle.svg';
import { ReactComponent as DesignAdviceIcon } from '../../assets/icons/design-advice.svg';
import { ReactComponent as PromotionsAndSalesMethodsIcon } from '../../assets/icons/promotions-sales-methods.svg';
import { ReactComponent as StackAndPinIcon } from '../../assets/icons/stack-and-pin.svg';
import { ReactComponent as HouseViewIcon } from '../../assets/icons/house-view.svg';
import { ReactComponent as HouseViewMapIcon } from '../../assets/icons/house-view-map.svg';
import { ReactComponent as AnalyticsIcon } from '../../assets/icons/analytics.svg';
import { SquaresPlusIcon, Square3Stack3DIcon } from '@heroicons/react/24/solid';
import { ReactComponent as UIIcon } from '../../assets/icons/ui.svg';
import { toggleMenu } from '../../store/actions/ui';
import { DevelopmentSelector, Logout } from './components';
import './styles.scss';
import { SidebarSeperator } from '../../components';

const MainLayout = ({ children }) => {
  const development = useSelector((state) => state.general.currentDevelopment);
  const { menuOpen } = useSelector((state) => state.ui);
  const optionalMenuItems = useSelector((state) => state.optionalMenuItems);
  const dispatch = useDispatch();

  return (
    <div className='main-layout'>
      <button
        onClick={(e) => dispatch(toggleMenu())}
        className={menuOpen ? 'menu-button open' : 'menu-button'}
      >
        <svg
          version='1.1'
          id='Layer_1'
          x='0px'
          y='0px'
          height='1.25rem'
          viewBox='0 0 64 64'
          fill='white'
        >
          <path d='M56.24,36.35H7.76c-2.4,0-4.35-1.95-4.35-4.35v0c0-2.4,1.95-4.35,4.35-4.35h48.48c2.4,0,4.35,1.95,4.35,4.35v0C60.59,34.4,58.64,36.35,56.24,36.35z' />
          <path d='M56.24,60.27H7.76c-2.4,0-4.35-1.95-4.35-4.35v0c0-2.4,1.95-4.35,4.35-4.35h48.48c2.4,0,4.35,1.95,4.35,4.35v0C60.59,58.32,58.64,60.27,56.24,60.27z' />
          <path d='M56.24,12.43H7.76c-2.4,0-4.35-1.95-4.35-4.35v0c0-2.4,1.95-4.35,4.35-4.35h48.48c2.4,0,4.35,1.95,4.35,4.35v0C60.59,10.48,58.64,12.43,56.24,12.43z' />
        </svg>
      </button>
      <SideBar>
        {development.type === 'signtouch-parent' && (
          <>
            <SideBarItem
            label='Analytics'
            icon={<AnalyticsIcon fill='#fff' />}
            to={`/developments/${development.id}/analytics`}
            />
            <SidebarSeperator additionalClasses={'margin-left-22'}/>
          </>
        )}
        <SideBarItem
          label='House Types'
          icon={<HomeIcon fill='#fff' />}
          plus={true}
          plusTo={`/developments/${development.id}/houseTypes/create`}
          plusText={'add house type'}
          to={`/developments/${development.id}/houseTypes`}
        />
        {/** site only Items */}
        {development.type === 'signtouch-site' && (
          <>
            <SideBarItem
              label='Plots'
              icon={<PlotIcon fill='#fff' />}
              plus={true}
              plusTo={`/developments/${development.id}/plots/create`}
              to={`/developments/${development.id}/plots`}
              plusText={'add plot'}
            />
            <SidebarSeperator additionalClasses={'margin-left-22'} />
            {optionalMenuItems.houseViewMenu &&
              <SideBarItem
                label= 'House Internal Configuration'
                icon={<HouseViewIcon fill='#fff' />}
                to={`/developments/${development.id}/house-view/configuration`}
              />
            }
            {optionalMenuItems.streetViewMenu &&
              <SideBarItem
                label= 'Street View Configuration'
                icon={<HouseViewIcon fill='#fff' />}
                to={`/developments/${development.id}/street-view/configuration`}
              />
            }
            {development.mapType === 'multi-floor' && (
              <>
                <SideBarItem
                  label='Multi Floor Configuration'
                  icon={<Square3Stack3DIcon fill='#fff' />}
                  to={`/developments/${development.id}/multi-floor/configuration`}
                />
                <SideBarItem
                  label='Multi Floor Map'
                  icon={<StackAndPinIcon fill='#fff' />}
                  to={`/developments/${development.id}/multi-floor/map`}
                />
              </>
            )}
            {(development.mapType === 'beabloo' || development.mapType === 'three-map') && (
              <>
                <SideBarItem
                  label='Basic Map Configuration'
                  icon={<MapIcon fill='#fff' />}
                  to={`/developments/${development.id}/basic-map/configuration`}
                />
                <SideBarItem
                  label='Basic Map Editor'
                  icon={<MapIcon fill='#fff' />}
                  to={`/developments/${development.id}/map-editor`}
                />
              </>
            )}
            <SidebarSeperator additionalClasses={'margin-left-22'} />
            <SideBarItem
              label='Local Information'
              icon={<LocalInformationIcon fill='#fff' />}
              to={`/developments/${development.id}/local-information`}
            />
            <SideBarItem
              label='Transport Links'
              icon={<TransportLinksIcon fill='#fff' />}
              to={`/developments/${development.id}/transport-links`}
            />
            <SideBarItem
              label='Static Pages'
              icon={<PageIcon fill='#fff' />}
              to={`/developments/${development.id}/pages`}
            />
            <SidebarSeperator additionalClasses={'margin-left-22'} />
            <SideBarItem
              label='Configuration'
              icon={<MetaDataIcon stroke='#fff' />}
              to={`/developments/${development.id}/metadata`}
            />
            <SideBarItem 
              label='Integrations'
              icon={<SquaresPlusIcon fill='white' />}
              to={`/developments/${development.id}/integrations`}
            />
            
            {/* <SideBarItem
              label='Galleries'
              icon={<ImageIcon fill='#fff'/>}
              to={`/developments/${development.id}/galleries`}
              plus
              plusTo={`/developments/${development.id}/galleries/create`}
              plusText={'create a gallery'}
            /> */}
          </>
        )} 

        {/** region only Items */}
        {development.type === 'signtouch-region' && (
          <>
            <SideBarItem
              label='Property Options'
              icon={<OptionIcon fill='#fff' />}
              to={`/developments/${development.id}/optionsOverview`}
            />
            <SidebarSeperator additionalClasses={'margin-left-22'} />
            <SideBarItem
              label='Promotions &amp; Sales Methods'
              icon={<PuzzleIcon fill='#fff' />}
              to={`/developments/${development.id}/promotions-sales-methods`}
            />
            <SidebarSeperator additionalClasses={'margin-left-22'} />
            <SideBarItem
              label='Player UI'
              icon={<UIIcon fill='#fff' />}
              to={`/developments/${development.id}/playerui`}
            />
          </>
        )}

        {/** Parent only Items */}
        {development.type === 'signtouch-parent' && (
          <>
            <SideBarItem
              label='Property Options'
              icon={<OptionIcon fill='#fff' />}
              to={`/developments/${development.id}/optionsOverview`}
            />
            <SidebarSeperator additionalClasses={'margin-left-22'} />
            <SideBarItem
              label='Promotions & Sales Methods'
              icon={<PromotionsAndSalesMethodsIcon fill='#fff' />}
              to={`/developments/${development.id}/promotions-sales-methods`}
            />
            <SideBarItem
              label='Design Advice'
              icon={<DesignAdviceIcon fill='#fff' />}
              to={`/developments/${development.id}/design-advice`}
            />
            <SidebarSeperator additionalClasses={'margin-left-22'} />
            <SideBarItem
              label='Branding'
              icon={<BrandingIcon fill='#fff' />}
              to={`/developments/${development.id}/properties`}
            />
            <SideBarItem
              label='Status Categorisation'
              icon={<CategoryIcon fill='#fff' />}
              to={`/developments/${development.id}/status-categorisation`}
            />
            <SideBarItem
              label='Modules'
              icon={<PuzzleIcon fill='#fff' />}
              to={`/developments/${development.id}/modules`}
            />
          </>
        )}
      </SideBar>

      <div className='page'>
        <div className='development-banner'>
          <DevelopmentSelector />

          <Logout />
        </div>
        <div className='content'>{children}</div>
      </div>
    </div>
  );
};

export default MainLayout;
